import {useState} from 'react';
import {Link} from 'react-router-dom';
import {MainMenuWithContext} from '../contexts/MainMenuContext';
import UrlsService from '../../../../../common/router/services/urlsService.mdl';
import UserMenu from '../components/user-menu/UserMenu';
import Profile from '../../profile/view/Profile';
import NotificationPanel from '../../../../user-notifications/components/notification-panel/NotificationPanel';
import useUserManager from '../../../../../common/hooks/useUserManager';
import ThemeSwitch from '../components/theme-switch/ThemeSwitch';
import theme from './theme.module.scss';
import ChangeLogsLinks from '../../../../change-logs/common/links/ChangeLogsLinks';
import ChartsLinks from '../../../../charts/links/ChartsLinks';
import DashboardsLinks from '../../../../dashboards/links/DashboardsLinks';
import ReportingLinks from '../../../../reporting/common/links/ReportsLinks';
import ScheduleReportsLinks from '../../../../schedule-reports/links/ScheduleReportsLinks';
import TimePresetsSelect from '../components/time-presets-select/TimePresetsSelect';
import {observer} from 'mobx-react';
import VidazooLogo from '../../../../../common/components/vidazoo-logo/VidazooLogo';
import {Menu, X} from 'lucide-react';

function MainMenu(): JSX.Element {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const closeMobileMenu = () => setIsMobileMenuOpen(false);

    return (
        <MainMenuWithContext>
            <div className={`${theme.menuContainer} flex justify-between items-center p-4`}>
                {/* Logo & Mobile Menu Toggle */}
                <div className="flex items-center">
                    <Link to={UrlsService.dashboards()} className="pl-4" onClick={closeMobileMenu}>
                        <VidazooLogo width={181} height={66} light/>
                    </Link>
                </div>
                <button
                    className="md:hidden p-2 text-white"
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                    {isMobileMenuOpen ? <X size={24}/> : <Menu size={24}/>}
                </button>

                {/* Desktop Menu */}
                <div className="hidden md:flex items-center space-x-4">
                    <DashboardsLinks/>
                    <ChartsLinks/>
                    <ReportingLinks/>
                    <ScheduleReportsLinks/>
                    <ChangeLogsLinks/>
                </div>

                {/* User Options */}
                <div className="hidden md:flex items-center space-x-4">
                    <TimePresetsSelect/>
                    <NotificationPanel/>
                    <ThemeSwitch/>
                    <UserMenu/>
                </div>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="md:hidden bg-black shadow-md p-4 space-y-4 pt-24">
                    <div onClick={closeMobileMenu}>
                        <DashboardsLinks isMobile/>
                    </div>
                    <div onClick={closeMobileMenu}>
                        <ChartsLinks isMobile/>
                    </div>
                    <div onClick={closeMobileMenu}>
                        <ReportingLinks/>
                    </div>
                    <div onClick={closeMobileMenu}>
                        <ScheduleReportsLinks isMobile/>
                    </div>
                    <div onClick={closeMobileMenu}>
                        <ChangeLogsLinks/>
                    </div>
                    <div onClick={closeMobileMenu}>
                        <TimePresetsSelect/>
                    </div>
                </div>
            )}
            <Profile/>
        </MainMenuWithContext>
    );
}

export default observer(MainMenu);
