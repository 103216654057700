import NavLinkGroup from '../../app/components/main-menu/components/nav-link-group/NavLinkGroup';
import NavLink from '../../app/components/main-menu/components/NavLink';
import UrlsService from '../../../common/router/services/urlsService.mdl';

interface IChartsLinksProps {
    isMobile?: boolean;
}

export default function ChartsLinks({isMobile}: IChartsLinksProps): JSX.Element {

    if (isMobile) {
        return (
            <NavLinkGroup to={UrlsService.charts()} label="Charts" iconName="icon-dashboard"/>
        )
    }

    return (
        <NavLinkGroup to={UrlsService.charts()} label="Charts" iconName="icon-dashboard">
            <NavLink className="m-6" to={UrlsService.charts()}>Charts List</NavLink>
            <NavLink className="m-6" to={UrlsService.createChart()}>Create Chart</NavLink>
        </NavLinkGroup>
    );
}
